@import "../../../../../public/mixins/mixins.scss";

.videoCardWrapper {
  margin: 0px 10px;
  border-radius: 20px;
  border: 1px solid #dff7ff;
  background: #fff;
  padding: 10px;
  min-width: 355px;
  @media (max-width: 465px) {
    min-width: 315px;
  }

  @media (max-width: 420px) {
    min-width: 265px;
  }

  .videoImgContainer {
    width: 100%;
    height: 160px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
  }

  .videoHeading {
    padding: 20px 10px;
    text-align: center;
    @include font(14px, 400, #363636, 0.56px, 23px);
  }
}
